<script setup lang="ts">
    import { computed, onMounted, ref } from 'vue';
    import { getOrCreateDataObject } from 'o365-dataobject';
    import { OPersonsLookup } from 'o365-system-lookups';
    import { userSession } from 'o365-modules';
    import { alert, confirm } from 'o365-vue-services';
    import { OPropertiesForm } from 'o365-data-properties';
    import { type DataItemModel } from "o365-dataobject";
    import { $t, utils } from 'o365-utils';

    import Stars from 'scopehazard.vue.components.Stars.vue';
    
    export interface IProps {
        itemId: number
    }

    interface Assigned {
        Type: 'Person' | 'Group',
        ID: number
    }

    const props = defineProps<IProps>();

    // const props = defineProps({
        // itemId: Number
    // });

    const dsHazard: DataItemModel = getOrCreateDataObject({
        id: 'hazard',
        viewName: 'aviw_Scope_Hazard',
        maxRecords: 1,
        whereClause: '',
        fields: [
            { name: "ID", type: "number" }
        ]
    });
    parent.window.dsHazard = dsHazard;

    const dsHazardActionAssessments: DataItemModel = getOrCreateDataObject({
        id: 'hazardActionAssessment',
        viewName: 'aviw_ScopeHazard_ItemsAssessments', 
        uniqueTable: "atbv_ScopeHazard_ItemsAssessments",
        allowUpdate: true,
        allowInsert: true,
        allowDelete: true,
        maxRecords: -1,
        whereClause: '',
        distinctRows: false,
        fields: [
            { name: "Created", type: "number" },
            { name: "CreatedBy_ID", type: "number" },
            { name: "Updated", type: "number" },
            { name: "UpdatedBy_ID", type: "number" },
            { name: "UpdatedByName", type: "string" },
            { name: "ID", type: "number" },
            { name: "Action_ID", type: "number" },
            { name: "Person_ID", type: "number" },
            { name: "PersonName", type: "string"},
            { name: "Group_ID", type: "number" },
            { name: "GroupName", type: "string"},
            { name: "ImagePrimKey", type: "string" },
            { name: "Assessment", type: "number" },
            { name: "Comment", type: "string" },
            { name: "CanEdit", type: "number" }
        ]
    });
    parent.window.dsHazardActionAssessments = dsHazardActionAssessments;

    const dsAction: DataItemModel = getOrCreateDataObject({
        id: 'hazardAction',
        viewName: 'aviw_Scope_ItemsScopeItems',
        maxRecords: 1,
        allowUpdate: true,
        allowInsert: false,
        allowDelete: false,
        whereClause: '',
        fields: [
            { name: "ID", type: "number" },
            { name: "Responsible_ID", type: "number" },
            { name: "ParentItem_ID", type: "number"}
        ]
    });
    parent.window.dsAction = dsAction;

    const dsGroupsLkp: DataItemModel = getOrCreateDataObject({
        id: 'groupsLkp',
        viewName: 'aviw_Scope_Groups', 
        maxRecords: 50,
        whereClause: '',
        distinctRows: false,
        fields: [
            { name: "ID", type: "number" },
            { name: "Name", type: "string" },
            { name: "OrgUnit", type: "string" },
            { name: "GroupMembers", type: "string" },
            { name: "IdPath", type: "string" }
        ]
    });
    parent.window.dsGroupsLkp = dsGroupsLkp;

    const editRowID = ref<number | null>(null);
    const assessmentComment= ref<string>('');
    
    const hoveredAssessments = ref<Object>({});

    const validAssessments = computed<Array<DataItemModel>>(() => dsHazardActionAssessments.data.filter(assessment => assessment.Assessment != 0));
    const averageAssessment = computed<number>(() => {
        if (validAssessments.value.length == 0) return 0.0;

        const sumScore = validAssessments.value.reduce((sum, assessment) => {
            return sum + assessment.Assessment; 
        }, 0);
        const totalScore = sumScore / validAssessments.value.length;
        return Math.round(totalScore * 10) / 10;
    });

    const hazardProperties = computed(() => dsHazard.data[0]?.properties);
    const actionProperties = computed(() => dsAction.data[0]?.properties);

    async function createNewAssessment(assigned: Assigned) {
        const personID = assigned.Type == 'Person' ? assigned.ID : null;
        const groupID = assigned.Type == 'Group' ? assigned.ID : null;

        await dsHazardActionAssessments.createNew({ Action_ID: props.itemId, Person_ID: personID, Group_ID: groupID, Assessment: 0 })
        await dsHazardActionAssessments.save();
        alert($t("New assessment created!"), "success", {autohide: true, delay: 3000});
    }

    async function deleteAssessment(assessment: DataItemModel) {
        const confirmation = await confirm({
            title: $t('Delete?'),
            message: $t('Are you sure you want to delete this assessment?')
        });
        if (!confirmation) return;

        if (dsHazardActionAssessments.data.length == 1) {
            alert($t('Deletion cancelled: \n There has to be at least one assessment of the action.'), "danger");
            return;
        }

        await dsHazardActionAssessments.deleteItem(assessment);
        await dsHazardActionAssessments.save();
        alert($t("Assessment deleted!"), "success", {autohide: true, delay: 3000});
    }

    async function saveEdit(assessment: DataItemModel) {
        await assessment.save();
        editRowID.value = null;
        alert($t("Assessment saved!"), "success", {autohide: true, delay: 3000});
    }

    async function cancelEdit(assessment: DataItemModel) {
        await assessment.cancelChanges()
        editRowID.value = null;
    }

    function setAssessment(currentAssessment: DataItemModel, newAssessment: number) {
        if (!currentAssessment.CanEdit) return;
        currentAssessment.Assessment = newAssessment;
        currentAssessment.save();
    }

    function setExperienceActionAssessment(newScore: number) {
        actionProperties.value.ExperienceActionAssessment = newScore;
        dsAction.save();
    }

    onMounted(async () => {
        dsHazardActionAssessments.recordSource.whereClause = `[Action_ID] = ${props.itemId}`;
        dsHazardActionAssessments.load();

        dsAction.recordSource.whereClause = `[ID] = ${props.itemId}`;
        await dsAction.load();

        dsHazard.recordSource.whereClause = `[ID] = ${dsAction.data[0].ParentItem_ID}`;
        dsHazard.load();
    });
</script>

<template>
    <ORowContainer v-if="actionProperties && hazardProperties">
        <div v-if="!hazardProperties.IsExperience">
            <h6 class="d-flex">
                <div class="me-1 text-muted">{{ $t('Assessments') }}</div>
                <div class="dropdown">
                    <button class="btn btn-sm btn-link py-0 mb-1 dropdown-toggle" data-bs-toggle="dropdown">
                        {{ $t('New Assessment') }}
                    </button>

                    <ul class="dropdown-menu">
                        <li><a class="dropdown-item" href="#" @click="createNewAssessment({ Type: 'Person', ID: userSession.personId })">Assign Me</a></li>
                        <li>
                            <OPersonsLookup
                                :bind="sel => createNewAssessment({ Type: 'Person', ID: sel.ID })"
                                noClear>
                                <template #person>
                                    <a class="dropdown-item" href="#">Assign Person</a>
                                </template>
                            </OPersonsLookup>
                        </li>
                        <li>
                            <ODataLookup
                                :dataObject="dsGroupsLkp"
                                :bind="sel => createNewAssessment({ Type: 'Group', ID: sel.ID})">
                                <template #target="{ target }">
                                    <a class="dropdown-item" :ref="target" href="#">Assign Group</a>
                                </template>

                                <OColumn field="ID" width="80" />
                                <OColumn field="Name" width="250" />
                                <OColumn field="GroupMembers" width="200" />
                                <OColumn field="OrgUnit" width="200" />
                            </ODataLookup>
                        </li>
                    </ul>
                </div>
            </h6>
        </div>

        <div class="card">
            <div class="card-header">
                <h6>
                    <template v-if="!hazardProperties.IsExperience">
                        <ORowContainer>
                            <div class="d-flex gap-2">
                                <div>{{ $t('Average Assessment') }}:</div>

                                <Stars :score="averageAssessment" :editable="false"/>

                                <div :title="$t('Number of Assessments')"> {{ validAssessments.length }}</div>
                            </div>

                            <div class="d-flex gap-2">
                                <div>
                                    {{ $t('Experience Action Assessment') }}: 
                                </div>
                                
                                <Stars :score="actionProperties.ExperienceActionAssessment" :editable="false"/>
                            </div>
                        </ORowContainer>
                    </template>

                    <template v-else>
                        <div class="d-flex justify-content-center">
                            <div>
                                {{ $t('Assessment') }} *
                            </div>
                        </div>
                    </template>
                </h6>
            </div>

            <div class="card-body py-2 row" :class="{ 'gap-3': !hazardProperties.IsExperience }">
                <template v-if="!hazardProperties.IsExperience">
                    <div v-for="(assessment, index) in dsHazardActionAssessments.data" class="col-12">
                        <div class="d-flex gap-1">
                            <div v-if="assessment.ImagePrimKey">
                                <img class="rounded" :src="`/api/file/view/stbv_System_PersonsImages/${assessment.ImagePrimKey}?mwidth=39`" v-if="assessment.Person_ID" :key = "assessment.Person_ID"/>
                            </div>

                            <div v-else>
                                <svg class="rounded" xmlns="http://www.w3.org/2000/svg" width="39" height="52" viewBox="0 0 20 20">
                                    <g fill="#54595d">
                                        <path d="M 10 11 C 4.08 11 2 14 2 16 L 2 19 L 18 19 L 18 16 C 18 14 15.92 11 10 11 Z"/>
                                        <circle cx="10" cy="5.5" r="4.5"/>
                                    </g>
                                </svg>
                            </div>

                            <div class="align-self-center">
                                {{ assessment.PersonName ?? assessment.GroupName }}
                            </div>

                            <template v-if="assessment.ID != editRowID">
                                <button class="btn btn-sm btn-link py-0 align-self-center" @click="editRowID = assessment.ID">
                                    <i class="bi bi-pencil-fill"></i>
                                </button>
                                

                                <button class="btn btn-sm btn-link py-0 ms-auto align-self-center" @click="deleteAssessment(assessment)">
                                    <i class="bi bi-x-circle"></i>
                                </button>
                            </template>
                        </div>

                        <div class="d-flex gap-2">
                            <div class="text-muted">
                                {{ utils.formatDate(assessment.Created, 'General Date Short Time') }}
                            </div>

                            <Stars :score="assessment.Assessment" :editable="assessment.CanEdit" @setScore="(newScore) => setAssessment(assessment, newScore)"/>
                        </div>  

                        <template v-if="assessment.ID == editRowID">
                            <div class="row gap-2">
                                <OFormControl :title="$t('Comment')" class="col-12">
                                    <OTextArea 
                                        class="form-control"
                                        v-model="assessment.Comment"
                                        :placeholder="$t('Comment...')" 
                                        autoGrow 
                                        noResize/>
                                </OFormControl>

                                <div class="col-12 d-flex justify-content-end gap-2">
                                    <button class="btn btn-primary btn-sm" @click="saveEdit(assessment)" :disabled="assessment.isSaving || !assessment.hasChanges">
                                        {{ $t('Save') }}
                                    </button>

                                    <button class="btn btn-secondary btn-sm" @click="cancelEdit(assessment)" :disabled="assessment.isSaving">
                                        {{ $t('Cancel') }}
                                    </button>
                                </div>
                            </div>
                        </template>

                        <template v-else>
                            <small class="text-muted">
                                Last updated by {{ assessment.UpdatedByName }} {{ utils.formatDate(assessment.Updated, 'General Date Short Time') }}
                            </small>

                            <div class="text-muted">
                                {{ assessment.Comment }}
                            </div>
                        </template>
                    </div>
                </template>

                <template v-else>
                    <div class="py-2">
                        <div class="d-flex justify-content-center">
                            <Stars :score="actionProperties.ExperienceActionAssessment" :editable="true" @setScore="setExperienceActionAssessment"/>
                        </div>

                        <OFormControl :title="$t('Comment')">
                            <OTextArea 
                                v-model="actionProperties.ExperienceActionAssessmentComment"
                                class="form-control" 
                                style="min-height:82px" 
                                autoGrow 
                                noResize/>
                        </OFormControl>
                    </div>
                </template>
            </div>
        </div>
    </ORowContainer>

    <div class="d-none" v-if="dsAction.data.length > 0 && dsHazard.data.length > 0">
        <OPropertiesForm
            :row="dsAction.data[0]" 
            bindingField="ID" 
            viewName="aviw_Scope_Items"/>

        <OPropertiesForm
            :row="dsHazard.data[0]" 
            bindingField="ID" 
            viewName="aviw_Scope_Items"/>
    </div>
</template>

<style scoped>
</style>